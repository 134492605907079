import React from 'react';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import {
  Button,
  Icon,
  FormControl,
  Select,
  MenuItem,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  InputLabel
} from '@material-ui/core';
import 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { getOutletsList } from 'app/redux/actions/OutletActions';

const UserForm = ({ onSave, initialData }) => {
  const dispatch = useDispatch();
  const { outletsList } = useSelector((state) => state.outlet);
  const formRef = React.useRef();
  const [form, setForm] = React.useState({
    name: '',
    email: '',
    mobile: '',
    password: '',
    passwordMatch: '',
    role: 'user',
    outlet_id: '',
    active: 0
  });

  React.useEffect(() => {
    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
      // console.log('FORM: ', form);
      if (value !== form.password) {
        // console.log("DOESN'T MATCH: ", value, ':', form.password);
        return false;
      }
      // console.log('MATCHED: ', value, ':', form.password);
      return true;
    });
  }, [form]);

  React.useEffect(() => {
    dispatch(getOutletsList());

    if (initialData) {
      setForm({
        id: initialData.id,
        name: initialData.name,
        email: initialData.email,
        mobile: initialData.mobile,
        role: initialData.role,
        active: initialData.active,
        outlet_id: initialData.outlet_id
      });
    }
    return () => {
      ValidatorForm.removeValidationRule('isPasswordMatch');
    };
  }, [dispatch, initialData]);

  const handleSubmit = (event) => {
    // console.log(form);
    // if (['user', 'store_admin'].includes('form.role') && form.outlet_id) {
    //   alert('Outlet is required for Store Admin and User types');
    //   return;
    // }
    onSave(form);
  };

  const handleChange = (event) => {
    // console.log('INPUT:', event.target.name, event.target.value);
    // event.persist();
    if (event.target.name === 'active') {
      setForm({ ...form, active: event.target.checked ? 1 : 0 });
    } else {
      setForm({ ...form, [event.target.name]: event.target.value });
    }
    // console.log('TEXT:', form, form.name);
  };

  return (
    <div>
      <ValidatorForm
        ref={formRef}
        onSubmit={handleSubmit}
        onError={(errors) => null}
      >
        {/* <Grid container spacing={6}>
          <Grid item lg={5} md={6} sm={12} xs={12}> */}
        <TextValidator
          className="mb-4 w-full"
          label="Name"
          variant="outlined"
          onChange={handleChange}
          type="text"
          name="name"
          value={form.name || ''}
          validators={['required']}
          errorMessages={['Name is required']}
        />
        <TextValidator
          className="mb-4 w-full"
          label="Email"
          variant="outlined"
          onChange={handleChange}
          type="email"
          name="email"
          value={form.email || ''}
          validators={['required', 'isEmail']}
          errorMessages={['Email is required', 'email is not valid']}
        />
        <TextValidator
          className="mb-4 w-full"
          label="Mobile"
          variant="outlined"
          onChange={handleChange}
          type="text"
          name="mobile"
          value={form.mobile || ''}
          validators={['required']}
          errorMessages={['Mobile is required']}
        />
        <TextValidator
          className="mb-4 w-full"
          label="Password"
          variant="outlined"
          onChange={handleChange}
          type="password"
          name="password"
          value={form.password || ''}
          validators={form.id ? [] : ['required']}
          errorMessages={['Password is required']}
        />
        <TextValidator
          className="mb-4 w-full"
          label="Password Confirm"
          variant="outlined"
          onChange={handleChange}
          type="password"
          name="passwordMatch"
          value={form.passwordMatch || ''}
          validators={
            form.id ? ['isPasswordMatch'] : ['required', 'isPasswordMatch']
          }
          errorMessages={[
            'Confirm Password field is required',
            "password didn't match"
          ]}
        />
        <FormControl component="fieldset">
          <FormLabel component="legend">User Role</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="role"
            value={form.role}
            onChange={handleChange}
            validators={['required']}
          >
            <FormControlLabel
              value="admin"
              control={<Radio />}
              label="Admin"
              disabled={
                form.id === JSON.parse(localStorage.getItem('auth_user')).id
              }
            />
            <FormControlLabel
              value="store_admin"
              control={<Radio />}
              label="Store Admin"
              disabled={
                form.id === JSON.parse(localStorage.getItem('auth_user')).id
              }
            />
            <FormControlLabel
              value="user"
              control={<Radio />}
              label="User"
              disabled={
                form.id === JSON.parse(localStorage.getItem('auth_user')).id
              }
            />
          </RadioGroup>
        </FormControl>

        {(form.role === 'user' || form.role === 'store_admin') && (
          <FormControl
            variant="outlined"
            className={`w-full`}
            style={{ minWidth: 100 }}
          >
            <InputLabel id="outlet-label">Outlet</InputLabel>
            <Select
              labelId="outlet-label"
              id="outlet"
              value={form.outlet_id}
              onChange={handleChange}
              name="outlet_id"
              label="Outlet"
              size="small"
            >
              {outletsList && outletsList.length ? (
                outletsList.map((outlet) => (
                  <MenuItem key={outlet.id} value={outlet.id}>
                    <span>{outlet.name_en}</span>
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={0}>
                  <span>Outlet not added</span>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        )}
        <Box>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            className="my-3"
          >
            <Icon>send</Icon>
            <span className="pl-2 capitalize">Submit</span>
          </Button>
        </Box>
      </ValidatorForm>
    </div>
  );
};
export default UserForm;
